.Nav {
  position: absolute;
  top: 0;
  width: 100%;
  display: none;
  justify-content: space-between;
  align-items: flex-start;
  font-family: "Open Sans";
}

.Nav .Wrapper {
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
}

.Nav .Wrapper input {
  border: 0;
  outline: 0;
  width: calc(50% - 20px);
}

.Buttons {
  color: #e5e5e5;
  font-size: 1rem;
  display: none;
  align-items: center;
  justify-content: center;
}

.Buttons div {
  padding: 10px;
  display: flex;
}

.Buttons .code {
  background-color: #ff69b4;
}

.Buttons .fish {
  background-color: #da438e;
}

.Buttons .tone {
  background-color: #c41a6f;
}
