.LinkBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
  color: var(--text-light);
}

.LinkBar .Wrapper {
  width: 80%;
  border-top: 1px solid var(--border-light);
}

.LinkBar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
