.Logo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Open Sans";
}

.Logo .Wrapper {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 200px;
  box-sizing: border-box;
}

.Logo .Wrapper svg {
  display: flex;
  width: 100%;
  height: 100%;
}

#text {
  animation-name: color_change;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes test {
  from {
    background-position: 100% 80%;
  }
  to {
    background-position: 100% 60%;
  }
}
/*
@keyframes color_change {
	0% { fill: hotpink; }
  25% { fill: orange; }
  50% { fill: red; }
  75% { fill: green; }
  100% { fill: rebeccapurple; }
}*/

@keyframes color_change {
  0% {
    fill: #ff69b4;
  }
  25% {
    fill: #ffa500;
  }
  50% {
    fill: #ff0000;
  }
  75% {
    fill: #008000;
  }
  100% {
    fill: #663399;
  }
}

@media screen and (max-width: 850px) {
  .Logo .Wrapper svg {
    width: 80%;
    transform: translateX(10%);
  }
}

.svgtext,
#text-mask {
  display: inline;
  text-align: center;
  font-size: 9rem;
  font-weight: 800;
  height: 100%;
  width: 100%;
}

.Logo .Title {
  color: #e5e5e5;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
